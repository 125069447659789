import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from "./LoginPage/LoginPage"
import Homepage from "./Homepage/Homepage";
import MVDDesktop from './MVDDesktop/MVDDesktop';
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/desktop" element={<MVDDesktop />} />
      </Routes>
    </Router>
  );
}

export default App;