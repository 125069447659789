import React from "react";
import { useSelector } from "react-redux";
import "./EvaButton.css";
import Eva from "../assets/Eva.png";
import EvaDashboard from "../EvaDashboard/EvaDashboard";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../redux/slices/sidebarSlices";

const EvaButton = () => {
  const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen);
  const dispatch = useDispatch();

  return (
    <>
      <button className="eva-btn" onClick={() => dispatch(toggleSidebar())}>      
        <span className="eva-btn-text">EVA</span>
      </button>
      <img className="eva-btn-image" alt="EVA" src={Eva} />
      <div className={`sliding-div ${isSidebarOpen ? "visible" : ""}`}>
        <EvaDashboard />
      </div>
    </>
  );
};

export default EvaButton;
