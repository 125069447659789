import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import sidebarReducer from './slices/sidebarSlices';

const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
