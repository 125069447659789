import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleLogin: (state) => {
      state.isAuthenticated = true;
    },
    handleLogout: (state) => {
      state.isAuthenticated = false;
    },
  },
});

export const { handleLogin, handleLogout } = authSlice.actions;
export default authSlice.reducer;
