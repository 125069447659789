import React from "react";
import "./EvaDashboard.css";
import Eva from "../assets/Eva.png";
import Bot from "../Bot";
import Dash from "../assets/dash.svg";
// import Layer from "../assets/layer.svg"
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../redux/slices/sidebarSlices";

const EvaDashboard = () => {
  const dispatch = useDispatch();

  return (
    <div className="eva-dashboard">
      <div className="overlap">
        <div className="overlap-wrapper">
          <div className="overlap-group">
            <img className="ellipse" alt="Ellipse" src={Eva} />
            <div className="text-wrapper">Eva</div>
            <div className="group-wrapper">
              <img
                src={Dash}
                alt=""
                onClick={() => dispatch(toggleSidebar())}
              />
            </div>
          </div>
        </div>
        <MemoizedBot />
      </div>
    </div>
  );
};

const MemoizedBot = React.memo(Bot);

export default EvaDashboard;
