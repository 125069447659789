import "./MVDDesktop.css";
import EvaButton from "../EvaButton/EvaButton";

function MVDDesktop() {
  const iframeStyle = {
    width: "100%",
    height: "100vh",
    borderWidth: "0",
  };

  return (
    <div className="MVDDesktop">
      <EvaButton />
      <iframe
        title="MVD Desktop_20231208"
        src="https://app.powerbi.com/reportEmbed?reportId=7cc6b9d6-4811-45c4-b519-6a219ebb63e2&autoAuth=true&ctid=ef05516d-1c43-414d-98c1-a5e469d028b2"
        allowFullScreen={true}
        style={iframeStyle}
      ></iframe>
    </div>
  );
}

export default MVDDesktop;
